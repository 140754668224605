  body {
  font-family: $sans-serif, $sans-serif-alt;
  font-weight: 300;
  color: $text-colour;
  background-color: $white;
  font-size: 16px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $sans-serif-alt;
}

.navbar {
  padding: 0;
  margin: 0;
}

.navbar-default {
  border: none;
  background-color: green;
  border-radius: 0;
}

.navbar-header {
  margin: 0;
  a img {
    display: block;
    max-width: 200px;
    max-height: 95px;
    width: auto;
    height: auto;
  }
}

#menu-primary-navigation,
#menu-apex-navigation {
  font-family: $sans-serif-alt;
}







/**
 * content blocks
 */

.content-blocks {
  padding: 30px 35px;
  &.row,
  >.container {
    padding-top: 15px;
  }
  /*
  > div.container:first-child{
      margin-top: 40px;
  }
  */
  >div.full-width:first-child {
    .content-block.panel_grid:first-child {
      margin-top: 0;
    }
  }
  .lead-intro {
    padding-top: 15px;
    p:first-of-type {
      font-size: 1.35em;
      font-weight: 300;
    }
    .woocommerce {
      padding-top: 20px;
    }
  }
  .main-width {
    .call-out {
      @extend .col-md-4;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: -33%;
      h4 {
        color: $text-colour;
      }
    }
  }
  .full-width {
    .panel_grid {
      .tab-pane.active {
        padding: 0;
      }
    }
  }
  .full-width:last-child {
    margin-bottom: 0;
  }

  .expanding_text {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    padding-bottom: 20px;
    .btn.btn-text {
      text-transform: uppercase;
      padding-left: 0;
      font-size: 0.9em;
      &:after {
        font-family: 'fontawesome';
        padding-left: 10px;
        content: "";
      }
      &.collapsed:after {
        content: "";
      }
    }
  }

  .full_width_image {
    div[style*="url("] {
      position: relative;
      .btn-photo-info {
        display: none;
      }
    }
  }
}

.breadcrumb-container {
  .breadcrumb {
    @extend .small;
  }
}

.twitter-typeahead {
  display: block !important;
  position: static !important;
}

.content-blocks .page-header:first-of-type {
  margin-top: 0;
}

.container-flex {
  display: flex;
  main {
    order: 2;
    flex: 1 1 auto;
    max-width: 100%;
    word-wrap: break-word;
    overflow: hidden;
  }
  aside {
    background: #38444f;
    order: 1;
    flex: 0 0 30rem;
    max-width: 30rem;
    position: relative;
    .sidebar-header-wrapper {
      background: #415970;
      padding: 5px;
      h3 {
        color: #fff;
        text-align: center;
        padding: 0;
        margin: 5px;
      }
    }
    ul {
      margin: 0;
      .dropdown-menu {
        padding-left: 0 !important;
      }
      &.open {
        display: block !important;
      }
      li li a {
        padding-left: 30px
      }
      li li li a {
        padding-left: 40px
      }
      li li li li a {
        padding-left: 50px
      }
      li {
        margin: 0;
        padding: 0;
        cursor: pointer;
        a {
          color: #fff;
          padding: 5px 15px 5px 20px;
          white-space: pre-wrap;
          &:hover {
            background: $bg-hover;
          }
          &:active {
            background: $brand-primary;
          }
          &:focus,
           ::selection,
          .nav.open>a {
            background-color: $brand-primary;
            text-shadow: none;
          }
        }
      }
    }
    .toggle-sidenav {
      display: block;
      content: " ";
      position: fixed;
      left: 30rem;
      top: calc(50vh - 15px);
      width: 15px;
      height: 30px;
      z-index: 99;
      background: #38444f;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      &:before {
        content: "\f0d9";
        font-family: 'fontawesome';
        font-size: 20px;
        color: $white;
        padding: 2px;
      }
      &:hover {
        background-color: $brand-primary;
      }
    }
    .affix-sidenav.affix {
      position: fixed;
      width: 30rem;
      top: 0;
      left: 0;
    }
    .topOfPage {
      position: absolute;
      top: calc(40vh - 15px);
    }

    .notTopOfPage {
      position: fixed;
      top: calc(50vh - 15px);
    }
  }
  &.aside-collapse {
    aside {
      flex: 0 0 0;
      overflow: hidden;
      .toggle-sidenav {
        left: 0;
        &:before {
          content: "\f0da";
          font-family: 'fontawesome';
          font-size: 20px;
          color: $white;
          padding: 2px;
        }
      }

      .affix-sidenav.affix {
        position: absolute;
      }
    }
  }
}

.aside-collapse aside .topOfPage{
  display: fixed !important;

}


aside {
  .dropdown-menu {
    position: relative;
    z-index: 0;
    float: none;
    min-width: 0;
    background: $bg-hover;
    &:hover {
      background: $bg-hover;
      color: #fff;
    }
    li {
      a {
        &:hover {
          background: $bg-hover;
          color: #fff;
        }
      }
    }
  }
  .dropdown-toggle {
    position: relative;
    &:before {
      content: "\f0da";
      font-family: 'fontawesome';
      width: 15px;
      text-align: left;
      margin-left: -15px;
      display: inline-block;
      color: $rgba-white-75;
    }
  }
  .dropdown-toggle[aria-expanded="true"] {
    &:before {
      content: "\f0d7";
      font-family: 'fontawesome';
    }
  }




  ul {
    .active {
      >.dropdown-toggle {
        background-color: rgba($brand-primary, 0.35);
      }
    }
    li.active-item {
      a {
        background-color: $brand-primary;
        color: $white;
      }
    }

    .dropdown-menu {
      padding-left: 10px !important;
      background: transparent;
      &:hover {
        background: transparent;
      }
    }
  }
}

div.breadcrumb-container ol {
  margin: 0;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background: $bg-hover;
}


.dropdown-menu>.active>a:focus {
  background-color: $brand-primary;
}

.nav-pills>li>a {
  border-radius: 0;
}









/* media queries */

@media (min-width: $screen-sm-min) {}

@media (min-width: $screen-md-min) {
  .content-blocks {
    .height-auto {
      min-height: auto !important;
    }
    &.common-blocks {
      >div {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: $screen-lg-min) {}

@media (min-width: 1440px) {
  .container {
    width: 1400px;
  }
  .content-blocks {
    max-width: 1200px;
    padding-left: 25px;
  }
}


.content-blocks {
  font-family: $sans-serif-alt;
}
